import React from 'react';

function Home() {
  return (
    <header className="App-header">
      <div className="leftMenu">
        <br />
        <br />
        <br />
        <p>Home</p>
        <p>Button</p>
      </div>
      <div className="container">
        <b>
          <span className="title">
            Secure&nbsp;
            <span className="gradient">Stonks</span>
            &nbsp;for Everyday Traders
          </span>
        </b>
      </div>
      <a
        className="App-link"
        href="https://reactjs.org"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn React
      </a>
    </header>
  )
}

export default Home;
