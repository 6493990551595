import React from "react";
import PriceChart from "../Graphs/PriceChart";

function Auctions() {
  return <div>
    <p>
      Auctions
    </p>
    <PriceChart />
  </div>

}

export default Auctions;
