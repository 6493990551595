/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  formatNumber, renderString, renderItem, rarityColors,
} from '../../utils/index'
import { GET_BAZAAR_ITEM, GET_ITEM_SCHEMA } from '../../graphs';
import PriceChart from '../Graphs/PriceChart';

function renderChange(change = 4.2069) {
  let className = 'change green';
  let symbol = '▲ +';
  if (change < 0) {
    className = 'change red';
    symbol = '▼ '
  }
  return (
    <span className={className}>
      {`${symbol} ${change * 100}`}
      %
    </span>
  )
}

function BazaarBody(): JSX.Element {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_BAZAAR_ITEM, {
    // eslint-disable-next-line @typescript-eslint/camelcase
    variables: { item_id: id },
    pollInterval: 6000,
  });
  const itemSchemaRequest = useQuery(GET_ITEM_SCHEMA);
  const itemSchema = itemSchemaRequest.data ? itemSchemaRequest.data.skyblock.items : {};
  const item = itemSchema[id] || {};
  if (loading) return <p>Loading...</p>;
  if (error || itemSchemaRequest.error) {
    return (
      <p className="red">
        Error!
        <pre>{JSON.stringify(error ?? itemSchemaRequest.error, null, 2)}</pre>
      </p>
    );
  }
  const values = { ...data.skyblock.bazaar.quick_status };
  const strings = { ...data.skyblock.bazaar }
  Object.keys(values).forEach((obj: string) => {
    if (obj.charAt(1) !== '_') {
      values[obj] = formatNumber(values[obj], 0);
    }
  })
  // console.log(itemSchema, item.item_id, item.damage, item.texture)
  return (
    <div>
      <div className="bazaarHeader">
        <div className="titleContainer">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              className="largeIconContainer"
              style={{
                margin: 'auto 0 auto -15px',
                // '1f8405116c1daa7ce2f012591458d50246d0a467bcb95a5a2c033aefd6008b63'
                ...renderItem(item),
              }}
            />
            <div style={{ margin: 'auto 0' }}>
              <span className="mediumText" style={{ fontWeight: 'normal' }}>
                {strings.name || id}
                {' '}
              </span>
              <span>{strings.category ? renderString(strings.category) : <span />}</span>
              <br />
              <span>
                <b>{item.tier ? renderString(`&8 ${item.tier} commodity`, false) : <br />}</b>
                <pre>{/* JSON.stringify(data, null, 2) */}</pre>
              </span>
            </div>
          </div>
          <br />
          <div className="price">
            <b>
              <span className="bigText">
                {formatNumber(strings.quick_status.buyPrice)}
                {' '}
              </span>
              {renderChange(-1.337)}
            </b>
          </div>
        </div>
        <div>
          <p style={{ color: 'gray' }}>
            Last updated: 20 seconds ago
          </p>
        </div>
        <div className="buttonContainer">
          <b>
            <span className="button buttonActive">Summary </span>
            <span className="button">Orders</span>
            <span className="button soonTM" title="Coming Soon!">Recipes</span>
          </b>
        </div>
      </div>
      <div className="bazaarContainer parent">
        <div className="chartContainer">
          <span className="subTitle">Chart goes here</span>
          <div style={{ display: 'block' }}>
            <PriceChart />
          </div>
          <div className="tableContainer">
            <span className="subTitle">Statistics Overview</span>
            <div className="tableFlex">
              <table>
                <tbody>
                  <tr>
                    <td className="tableTitle">Sell Price</td>
                    <td className="tableValue">{formatNumber(strings.quick_status.sellPrice)}</td>
                  </tr>
                  <tr>
                    <td className="tableTitle">Sell Volume</td>
                    <td className="tableValue">{values.sellVolume}</td>
                  </tr>
                  <tr>
                    <td className="tableTitle">Instantly Sold 7d</td>
                    <td className="tableValue">{values.sellMovingWeek}</td>
                  </tr>
                  <tr>
                    <td className="tableTitle">Sell Orders</td>
                    <td className="tableValue">{values.sellOrders}</td>
                  </tr>
                </tbody>
              </table>
              <table>
                <tbody>
                  <tr>
                    <td className="tableTitle">Buy Price</td>
                    <td className="tableValue">{formatNumber(strings.quick_status.buyPrice)}</td>
                  </tr>
                  <tr>
                    <td className="tableTitle">Buy Volume</td>
                    <td className="tableValue">{values.buyVolume}</td>
                  </tr>
                  <tr>
                    <td className="tableTitle">Instantly Bought 7d</td>
                    <td className="tableValue">{values.buyMovingWeek}</td>
                  </tr>
                  <tr>
                    <td className="tableTitle">Buy Orders</td>
                    <td className="tableValue">{values.buyOrders}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="infoContainer">
          <p>
            <span className="subTitle">Related Items</span>
            {strings.related ? strings.related.map((id: string) => {
              const item = itemSchema[id] || {}
              return <a href={`/bazaar/${id}`}><p>{item.name}</p></a>
            }) : <br />}
          </p>
        </div>
      </div>
    </div>
  );
}

function Bazaar() {
  return (
    <div>
      <BazaarBody />
    </div>
  )
}

export default Bazaar;
