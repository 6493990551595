/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import locations from './locations.json';

type locations = {
  [x: string]: string
}

type mcColors = {
  [x: string]: string[]
}

type rarityColors = {
  [x: string]: string
}

export const rarityColors: rarityColors = {
  common: 'f',
  uncommon: 'a',
  rare: '9',
  epic: '5',
  legendary: '6',
  mythic: 'd',
  special: 'c',
  null: 'f',
};

const mcColors: mcColors = {
  // Foreground - background
  '&0': ['#000000', '#000000'],
  '&1': ['#0000AA', '#00002A'],
  '&2': ['#00AA00', '#002A00'],
  '&3': ['#00AAAA', '#002A2A'],
  '&4': ['#AA0000', '#2A0000'],
  '&5': ['#AA00AA', '#2A002A'],
  '&6': ['#FFAA00', '#2A2A00'],
  '&7': ['#AAAAAA', '#2A2A2A'],
  '&8': ['#555555', '#151515'],
  '&9': ['#5555FF', '#15153F'],
  '&a': ['#55FF55', '#153F15'],
  '&b': ['#55FFFF', '#153F3F'],
  '&c': ['#FF5555', '#3F1515'],
  '&d': ['#FF55FF', '#3F153F'],
  '&e': ['#FFFF55', '#3F3F15'],
  '&f': ['#FFFFFF', '#3F3F3F'],
};

export function renderString(string: string, textShadow = true): JSX.Element[] {
  const codes: (string | number)[] = [];
  string.match(/&\w/g)!.forEach((element) => {
    codes.push(element);
  });
  const parts: string[] = string.split(/&\w/g);
  parts.shift();

  const spans: JSX.Element[] = [];
  parts.forEach((part, i) => {
    const [color, shadow] = mcColors[codes[i]];
    spans.push(<span style={{ color, textShadow: textShadow ? `${shadow} 0 0 5px` : 'none' }} key={part}>{parts[i]}</span>);
  });
  return spans;
}

export function abbreviateNumber(num: number, roundingDecimals = 2) {
  if (!num) {
    return '-';
  } if (num >= 1000 && num < 1000000) {
    return `${Number((num / 1000).toFixed(1))}k`;
  } if (num >= 1000000 && num < 1000000000) {
    return `${Number((num / 1000000).toFixed(1))}m`;
  } if (num >= 1000000000 && num < 1000000000000) {
    return `${Number((num / 1000000000).toFixed(1))}b`;
  } if (num >= 1000000000000) {
    return `${Number((num / 1000000000000).toFixed(1))}t`;
  }
  return num.toFixed(roundingDecimals);
}

export function formatNumber(num: number, fractionDigits = 2): string {
  const regex = /(\d+)(\d{3})/;
  return String(num.toFixed(fractionDigits)).replace(/^\d+/, (w) => {
    let word = w;
    while (regex.test(word)) {
      word = word.replace(regex, '$1,$2');
    }
    return word;
  });
}

export function renderItem({ item_id = 0, damage = 0, texture = '' }) {
  const style = {
    height: '128px',
    width: '128px',
    background: '',
    backgroundSize: 'auto',
  }
  if (texture) {
    console.log('yay')
    style.background = `url(https://www.mc-heads.net/head/${texture})`;
    style.backgroundSize = 'cover';
    return style;
  }
  // @ts-ignore
  const location = locations[`${item_id}_${damage}`];
  if (location) {
    style.background = `url(/img/inventory/items.png) ${location}`;
    // @ts-ignore
    style.transform = 'scale(0.75)';
  }
  return style;
}
