import React from 'react';

function Footer() {
  return (
    <footer>
      <div className="footerContainer">
        <span>Powered by </span>
        <a
          className="gradient"
          href="https://docs.slothpixel.me"
          target="_blank"
          rel="noopener noreferrer"
        >
          Slothpixel API
        </a>
        <p>Not affiliated with Hypixel Inc. or Mojang AB</p>
      </div>
    </footer>
  )
}

export default Footer;
