import { gql } from '@apollo/client';

export const GET_ITEM_SCHEMA = gql`
    query ItemList {
        skyblock {
            items
        }
    }
`;

export const GET_BAZAAR_ITEM = gql`
    query BazaarItem($item_id: String!) {
        skyblock {
            bazaar(item_id: $item_id) {
                quick_status {
                    sellPrice,
                    sellVolume,
                    sellMovingWeek,
                    sellOrders,
                    buyPrice,
                    buyVolume,
                    buyMovingWeek,
                    buyOrders,
                }
                name,
                category,
                related,
            }
        }
    }
`;
