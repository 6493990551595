import React, { useEffect, useState } from 'react';
import { resolveItem } from '../../utils/resolveItem';
import {
  ApolloClient, InMemoryCache, ApolloProvider, useQuery,
} from '@apollo/client';
import { GET_BAZAAR_ITEM, GET_ITEM_SCHEMA } from '../../graphs';
import { createBrowserHistory } from 'history';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import Footer from '../Footer/Footer';
import Home from '../Home/Home';
import Auctions from '../Auctions/Auctions';
import Bazaar from '../Bazaar/Bazaar';

const client = new ApolloClient({
  uri: 'https://api.slothpixel.me/api/graphql',
  cache: new InMemoryCache(),
});

// const history: any = createBrowserHistory();

function ItemSchema() {
  const { loading, error, data } = useQuery(GET_ITEM_SCHEMA);
}

function App() {
  // console.log(resolveItem('aotd'));
  const [data, setData] = useState({});
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <div className="App">
          <div className="navbar">
            <div className="nameContainer navbarItem gradient">
              <p><b>HypixelStonks</b></p>
            </div>
            <a href="/bazaar" className="navbarItem"><p>Bazaar</p></a>
            <a href="" className="navbarItem soonTM"><p>Auctions</p></a>
            <div className="navbarItem searchContainer">
              <input type="text" placeholder="🔎 Search for items" name="search" />
            </div>
            <p className="login navbarItem"><a href="">Login</a></p>
          </div>
          <hr />
          {/*
          <div className="marquee">
            <p>
              <span>Enchanted Redstone Block </span>
              <span className="green">▲ +420.69% </span>
              <span>Enchanted Pufferfish </span>
              <span className="red">▼ -23.45% </span>
            </p>
          </div>
          */}
          <div className="pageContent">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/auctions" component={Auctions} />
              <Route exact path="/bazaar/:id" component={Bazaar} />
            </Switch>
          </div>
          <Footer />
        </div>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
