import React, { Component } from 'react';
import * as d3 from 'd3';

const data = require('./sample-data.json')

const isLoaded = false;

class PriceChart extends Component<any, any> {
  componentDidMount() {
    this.drawChart();
  }

  drawChart() {

  }

  render() {
    return <div id={`#${this.props.id}`} />
  }
}

export default PriceChart;
